.content__spinner {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
    margin-bottom: 40px;
    justify-content: space-evenly;

}

.universalspin {
    display: flex;

}
.SpinneyBox {
    display: flex;
    width: 146px;
    height: 51px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    /* background: rgba(26, 60, 91, 0.20); */
    border-radius: 10px;
}

.blockRightTop {
    width: 230px;
    height: 51px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    /* background: rgba(26, 60, 91, 0.20); */
    border-radius: 10px;
    margin-left: 3px;
   
}
.expired {
    position: absolute;
    bottom: 53px;
    left: 42px;
    width: 84px;
    height: 24px;
    border-radius: 20px;
    line-height: 25px;
    align-items: center;
    border-radius: 20px;
}

.boxColorSpinney {
    width: 20px;
    height: 45px;
    border-radius: 13px;
    margin: 3px 8px 3px 3px;

}
.infoPapBlock {
    width: 128px;
    height: 26px;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    display: flex;
    margin:12px 6px 12px 8px;
    position: relative;
}
.titleDay {
    font-size: 16px;
    line-height: 11px;
    margin: 3px 6px 0px 6px;
}

.titlePap {
    width: 36px;
    height: 22px;
    border-radius: 20px;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 2px 2px 2px 2px;
    

}

.papDay{
    position: absolute;
    top: 2px;
    right: 2px;
    width: 36px;
    height: 22px;
    border-radius: 20px;
    border-radius: 20px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
} 

/* winter-fon */
.winter_top {
    background: url(../../../img/winter1.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 395px;
    height: 56px;   
    position: absolute;
    top: -20px;
    left: -5px;
    z-index: 3;
    pointer-events: none;
}

.winterFonBtn {
    background: url(../../../img/winter2.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 146px;
    height: 20px;
    position: absolute;
    bottom: 30px;
    right: 26px;
    z-index: 120;
    pointer-events: none;
}
.fonCardSpinner{
    background: url(../../../img/Group\ 690.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 380px;
    height: 658px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 0;
    pointer-events: none;

}


.fonCardLights {
    background: url(../../../img/Lights\ 1.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 378px;
    height: 329px;
    position: absolute;
    top: 56px;
    left: 3px;
    z-index: 3;
    pointer-events: none;
}

/*  */

/* mess */

.toast,
.Toastify {
    z-index: 1000;
}

.foo-bar.Toastify__toast-theme--light {
    position: relative;
    top: 80px;
    right: 40px;
    width: 300px;
    background: none;
    font-family: Dongle;
    font-size: 20px;
    line-height: 20px;
    font-style: normal;
    font-weight: 300;
    color: #fff;
    border-radius: 5px;
    backdrop-filter: blur(24px);
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}



.foo-bar .Toastify__close-button--light {
    color: #fff;
    opacity: 1;

}


.jingles{
    background-size: 100%;
    background-repeat: no-repeat;

}


.foo-bar .Toastify__close-button>svg {
    fill: currentColor;
    margin-top: 4px;
    margin-left: 3px;
    height: 16px;
    width: 14px;

}

.react-toastify {
    z-index: 1000;
}

.react-toastify__toast {
    z-index: 1000;
}

.react-toastify__toast-body {
    z-index: 1000;
}

.foo-bar.Toastify__toast {
    overflow: visible;
}


.app-wrapper .Toastify__progress-bar {
    border-radius: 5px;
}

.toast-message {
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    backdrop-filter: blur(25px);
    color: #fff;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
}



.rareSpin,
.promoSpin,
.commonSpin,
.uncommonSpin,
.violettaSpin,
.sunshineSpin {
    margin-right: 40px;
}

.top__menu__name {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
}

.btn.rare,
#rare__3,
.Rare,
#frosty,
.frosty {
    background: linear-gradient(180deg, #69CDFF 0%, #2E7CF5 100%);
}

.btn.promo,
.promo,
.Promo,
#promo__0 {
    background: linear-gradient(180deg, #535D7A 0%, #434D67 100%);
}

.btn.common,
.common,
.Common,
#common__1 {
    background: linear-gradient(180deg, #A3A7B6 0%, #4E556B 100%);
}

.btn.uncommon,
.uncommon,
.Uncommon,
#Uncommon__bg,
#uncommon__2 {
    background: linear-gradient(180deg, #A4D685 0%, #538E2E 100%);
}

.btn.epic,
#epic__4,
.epic__4,
.violetta,
.Epic,
#violetta {
    background: linear-gradient(180deg, #BE76E4 0%, #6D4AAC 100%);
}

.btn.legend,
#legend__5,
.sunshine,
.Legend,
#sunshine__5,
#sunshine {
    background: linear-gradient(180deg, #FFD171 0%, #8D6F36 100%);
}

.top__menu__name,
.bottom__menu {
    width: 380px;
    height: 51px;
    border-radius: 10px;

    
}
.top__menu__name.Basic,
.top__menu__name.Ticket,
.top__menu__name.Elite{
    background: rgba(0, 0, 0, 0.2);
}

.title {
    font-size: 32px;
    line-height: 40px;
    height: 29px;
}

.craft__right__content .title {
    font-size: 32px;
    height: 41px;
    position: absolute;
    right: 56px;
}

.nft__card .title {
    height: 41px;
}

.param {
    text-align: left;
    font-size: 16px;
    line-height: 10px;
    color: rgba(255, 255, 255, 0.50);
}

.fgf {
    height: 509px;
}

.player {
    width: 82px;
    height: 26px;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    position: absolute;
    left: 18px;
}

.close {
    position: absolute;
    top: 12px;
    right: 8px;
    background: url(../../../img/Unstake.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 26px;
    width: 26px;
    cursor: pointer;
    border-radius: 50%;
}

.close:hover {
    background: url(../../../img/Unstake\ \(2\).svg);
    background-repeat: no-repeat;
}

.close__ether {
    position: relative;
    background: url(../../../img/Union\ \(6\).webp);
    background-size: 50%;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-top: 8px;
    margin-right: 6px;
    background-position-x: center;
}

.ether {
    position: relative;
    background: url(../../../img/Group\ 201.svg);
    /* background: url(../../../img/Group\ 197.svg); */
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    cursor: pointer;
    z-index: 0;
    border-radius: 50%;
}

.ether:hover {
    background: url(../../../img/Group\ 201.svg);
    /* background: url(../../../img/Group\ 686.webp); */
    background-repeat: no-repeat;
    /* background-position: center;
    background-size: 100%; */
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.firstDiv,
.hideDiv {
    display: none;
}


#runa_promo__0,
#runa_common__1,
#runa_rare__2,
#runa_epic__3,
#runa_uncomon__4,
#runa_legend__5 {
    height: 122px;
    width: 122px;
    position: absolute;
    left: -11px;
    top: -12px;
    z-index: 3;
 
}

#runa_promo__0 {
    background: url(/src/img/marketrunaPrommo.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

#runa_common__1 {
    background: url(/src/img/marketrunaCommon.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 122px;
    width: 122px;
}

#runa_rare__2 {
    background: url(/src/img/marketrunaRare.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

#runa_epic__3 {
    background: url(/src/img/Spinner\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

#runa_uncomon__4 {
    background: url(/src/img/marketrunaUncommon.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

#runa_legend__5 {
    background: url(/src/img/marketrunaLegend.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

#bag__runa {
    background: url(../../../img/Group\ 15\ \(5\)\ 1.svg);
    background-repeat: no-repeat;
    height: 101px;
    width: 100px;
    position: absolute;
    bottom: 26px;
    z-index: 1;
}

.unstake__run {
    background: url(../../../img/ButtonSoul.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    backdrop-filter: blur(24px);
    height: 34px;
    width: 34px;
    position: absolute;
    top: 490px;
    left: 214px;
    z-index: 90;
    border-radius: 50px;
    cursor: pointer;

}

.unstake__run:hover {
    background: url(../../../img/ButtonS\ \(3\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 34px;
    width: 34px;
}


.closeCard {
    width: 26px;
    height: 26px;
    background: url(../../../img/Group\ 747.webp);
    border-radius: 50%;
    background-size: 100%;
    cursor: pointer;
}

.closeCard:hover,
.btnRig:hover,
.RegistlistOffer:hover,
.anchor:hover,
.cloudwallet:hover,
.btn__register:hover,
.register:hover,
.leaf__one__button:hover {
    position: relative;
    z-index: 100;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    animation: shadow-pulse 1s infinite;
}

.bottom__menu button:hover {
    width: 152px;
    height: 30px;
    border-radius: 15px;
    position: relative;
    z-index: 100;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    animation: shadow-pulse 1s infinite;
}
.btn__claim:hover,
.Winnerslist:hover,
.Registlist:hover,
.Ticket1 .player__assets:hover,
.Token1 .player__assets:hover,
.Assets1 .player__assets:hover,
.wombat:hover,
.fonTicketHeader:hover,
.btnTicket:hover,
.bottom__mobMenu .wax__link:hover,
.lvl:hover,
.amount button:hover,
.copy__link:hover,
.refbtn:hover,
.DropBuy:hover,
.Step1:hover,
.Step2:hover,
.Step3:hover,
.cancelRuna:hover,
.btn__modal .unruna:hover,
.btn__modal button:hover,
.stakeRuna:hover,
.fon__left__button:hover,
.fon__right__button:hover,
.log_out:hover,
.map__link:hover,
.menu__link:hover,
.burn:hover,
.settins:hover {
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    animation: shadow-pulse 1s infinite;
}
.burnSpinney:hover,
.soulClose:hover, 
.mClose:hover,
.mOpen:hover,
.unstake__run:hover,
.runa__noactiv:hover,
.close:hover,
.ether:hover,
.ether__start:hover {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    animation: shadow-pulse 1s infinite;
}


@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    }

    70% {
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}




.block__ether {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    width: 52px;
    height: 26px;
    position: absolute;
    top: 12px;
    right: 48px;
    display: flex;
    justify-content: space-between;
}

.noactiv__ether {
    /* opacity: 0; */
    width: 52px;
    height: 26px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    position: absolute;
    top: 12px;
    right: 39px;
    display: flex;
    justify-content: space-between;
    
}

.activ__ether {
    /* opacity: 0; */
    width: 52px;
    height: 26px;
    background: rgba(130, 193, 47, 0.90);
    box-shadow: 0px 0px 3px 0px rgba(130, 193, 47, 0.90), 0px 0px 7px 0px rgba(130, 193, 47, 0.80), 0px 0px 12px 0px rgba(130, 193, 47, 0.70), 0px 0px 20px 0px rgba(130, 193, 47, 0.50), 0px 0px 1.5px 0px #FFF;
    border-radius: 20px;
    position: absolute;
    top: 12px;
    right: 48px;
    display: flex;
    justify-content: space-between;
    transition: 0.3s;
    

}

.ether__ready {
    position: relative;
    background: url(../../../img/Check-verified.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 24px;
    height: 24px;
    transition: 0.3s;
}

.ether__start {
    position: relative;
    background: url(../../../img/Group\ 198.svg);
    background-repeat: no-repeat;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    top: 2px;
    right: 2px;
    transition: 0.3s;
    cursor: pointer;
    z-index: 10;
}

/* --------------- */

.bottom__menu {
    background: rgba(0, 0, 0, 0.2);
    /* background: rgba(26, 60, 91, 0.20); */
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 9988;
}

.content__spinner .bottom__menu {
    position: absolute;
    bottom: -58px;
}

.bottom__menu button {
    height: 30px;
    width: 152px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 3;
}

.bottom__menu button:first-child:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}


/* 
.btn__register:hover,
#uncommon:hover,
#promo:hover,
#common:hover,
#violetta:hover,
#sunshine:hover,
.bottom__menu button:last-child:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
} */
#b:hover,
.btn__register:hover,
#bt:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.btn#rare__3:hover,
.btn#uncommon__2:hover,
.btn#promo__0:hover,
.btn#common__1:hover,
.btn#epic__4:hover,
.btn#sunshine__5:hover,
.btn:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.bottom__menu button:first-child {
    border-radius: 20px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    margin: 8px 8px 8px 22px;
    line-height: 16px;
}

.bottom__menu button:last-child {
    margin: 8px 22px 8px 8px;
}

.card__player__blue {
    background: url(../../../img/Group\ 23.png) no-repeat;
    /* background: url(../../../img/Group\ 696.webp); */
    background-repeat: no-repeat;
    background-size: 100%;
    width: 380px;
    height: 543px;
    position: relative;
    border-radius: 10px;
    /* z-index: 2; */
  
  
}

.card__player__block {
    margin: 17px 17px 17px 0px;
}

.card__player {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

/* ----progressbar- */

.progress__block {
    height: 21px;
    margin-top: 18px;
    margin-left: 28px;
}

.progressbar {
    width: 183px;
    height: 9px;
    overflow: hidden;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 3px;
   
}

.progress__fon {
    width: 183px;
    height: 9px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
}

.offer1 .progress__fon{
    box-shadow: none;
}

.progressPercent {
    height: 7px;
}

.progressSpeed {
    width: 181px;
    display: flex;
    justify-content: space-between;
    line-height: 17px;
    font-size: 20px;
    padding: 0;
    margin: 0;
}

/* .activeSpins {
    border-radius: 13px;
    background: rgba(240, 128, 25, 0.80);
    box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
    font-family: 'Dongle', sans-serif;
    padding: 0;
}
#item.activeSpins {
    background: rgba(240, 128, 25, 0.80);
}
.card__player__btn.activeSpins .SpinSpid {
    color: #2C2F3A;

} */


.card__player__block {
    /* display: flex; */
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 142px;
}

.card__player__block button {
    position: relative;
    /* height: 21px;
    width: 20px; */
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border: none;
    z-index: 8;

}

#item {
    height: 21px;
    width: 20px;
}

#item:not(:last-of-type) {
    margin-right: 5px;
}

button#item.card__player__btn.active {
    color: #2C2F3A;
    border-radius: 15px;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    box-shadow: 0px 0px 3px 0px rgba(240, 128, 25, 0.90), 0px 0px 7px 0px rgba(240, 128, 25, 0.80), 0px 0px 12px 0px rgba(240, 128, 25, 0.70), 0px 0px 20px 0px rgba(240, 128, 25, 0.50), 0px 0px 1.5px 0px #FFF;
    
}

.card__player__btn .SpinSpid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    height: 24px;

}
.card__player__btn.active .SpinSpid {
    color: #2C2F3A;
    font-weight: 500;
  
}


button#item.card__player__btn {
    line-height: 16px;
}
.card__player__btn {
    padding: 0;
}
button#item.card__player__btn.active a {
    color: #2D303C;
    font-weight: 400;
}

#item a {
    display: flex;
    /* align-items: center; */
    line-height: 22px;
    justify-content: center;
    height: 23px;
}

/* info */

.info__block {
    height: 69px;
    display: flex;
    justify-content: space-between;
    margin: 0px 28px 0px 28px;
    flex-direction: row-reverse;
}

.info__block__right {
    width: 183px;
    height: 69px;
}

.info__block__left div {
    width: 122px;
    height: 30px;
    border-radius: 5px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    border-radius: 15px;
    justify-content: center;
    justify-content: space-between;
}

.first span:first-child,
.second span:first-child {
    margin-left: 10px;
}

.consumption,
.multiplier {
    margin-right: 9px;
}

.info__block__right {
    position: relative;
    border-radius: 5px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    border-radius: 13px;
    text-align: center;
    z-index: 1;
}

.info__block__left div {
    display: flex;
    align-items: flex-end;
    line-height: 26px;
}


.icon__fonStor {
    background: url(../../../img/coinStor.webp) no-repeat;
    background-size: 100%;
    width: 75px;
    height: 75px;
    position: absolute;
    top: -1px;
    right: 0;
}

.storage__bottom,
.storage {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 0.6;
    font-weight: 400;
}

.storage:first-child {
    pointer-events: none;
    font-size: 32px;
    position: absolute;
    top: 14px;
    right: 14px;
    font-weight: 400;
    z-index: 2;
}

.storage__bottom {
    position: absolute;
    top: 32px;
    right: 14px;
    z-index: 2;
}

.title__s {
    position: absolute;
    top: 30px;
    left: 15px;
    font-size: 32px;
}

/* ---bottom card */
.runa,
.promo__bg,
.common__bg,
.uncommon__bg,
.violetta__bg,
.sunshine__bg {
    border-radius: 50px;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    width: 100px;
    height: 100px;
    position: absolute;
    left: 140px;
    bottom: 22px;
    z-index: 2;
    cursor: pointer;
}


.runa__bg {
    background-image: url(../../../img/Group\ 132\ \(1\).svg);
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: 1s;
    bottom: 53px;
    height: 60px;
    width: 50px;
}


.runa__noactiv,
.promo__noactiv,
.common__noactiv,
.uncommon__noactiv,
.violetta__noactiv,
.sunshine__noactiv {
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
    height: 34px;
    width: 34px;
    top: 455px;
    left: 173px;
    cursor: pointer;
    border-radius: 50%;
    background: url(../../../img/ButtonSoul\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

.runa__noactiv:hover,
.promo__noactiv:hover,
.common__noactiv:hover,
.uncommon__noactiv:hover,
.violetta__noactiv:hover,
.sunshine__noactiv:hover {
    background: url(../../../img/ButtonS\ \(2\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    z-index: 10;
    height: 34px;
    width: 34px;
    top: 455px;
    left: 172px;
    border-radius: 50%;
}
/* 
.runa__noactiv {
    background: url(../../../img/ButtonSoul\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

.promo__noactiv {
    background: url(../../../img/ButtonSoul\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

.common__noactiv {
    background: url(../../../img/Group\ 203\ \(5\).svg);
}

.uncommon__noactiv {
    background: url(../../../img/Group\ 203\ \(6\).svg);
}

.violetta__noactiv {
    background: url(../../../img/Group\ 203\ \(8\).svg);
}

.sunshine__noactiv {
    background: url(../../../img/Group\ 203\ \(7\).svg);
} */

.fon__right {
    background: url(/src/img/Rectangle\ 13.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 92px;
    height: 113px;
    left: 30px;
}

.fon__left,
.fon__right {
    width: 92px;
    height: 113px;
    position: absolute;
    bottom: 20px;
}

.fon__left {
    background: url(/src/img/Rectangle\ 13.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    right: 30px;
    width: 92px;
    height: 113px;
}

.fon__left__button,
.fon__right__button {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    width: 84px;
    height: 24px;
    border: none;
    position: absolute;
    z-index: 999;
    bottom: 24px;
    display: flex;
    line-height: 27px;
    justify-content: center;
}

.fon__right__button {
    left: 34px;
}

.fon__left__button:hover,
.fon__right__button:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
}

.fon__left__button {
    right: 34px;
}

.item__spiner__runa,
.item__spiner {
    position: absolute;
    left: -13px;
    top: -17px;
    
    width: 118px;
    height: 119px;
    z-index: 9;
}


#promo_item__0 {
    background: url(/src/img/marketMagmus.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#common_item__1 {
    background: url(/src/img/marketCommon\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
   

 
}

#uncommon_item__2 {
    background: url(/src/img/marketUncommon.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#rare_item__3 {
    background: url(/src/img/marketRare.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#epic_item__4{
    background: url(/src/img/marketEpic\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#legend_item__5 {
    background: url(/src/img/marketLegend\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}
#jing_item__6 {
    background: url(/src/components/StopAnimation/01\ \(Prommo\)\ Jingles_00051.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

#promo_runa__0 {
    background: url(/src/img/marketrunaPrommo.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#common_runa__1 {
    background: url(/src/img/marketrunaCommon.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#uncommon_runa__2 {
    background: url(/src/img/marketrunaUncommon.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#frosty_runa__3 {
    background: url(/src/img/marketrunaRare.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#violetta_runa__4 {
    background: url(/src/img/Spinner\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

#sunshine_runa__5 {
    background: url(/src/img/marketrunaLegend.webp);
    background-repeat: no-repeat;
    background-size: 100%;
}

.sprite {
    position: absolute;
    top: 90px;
    left: 10px;
    background: url(/src/img/Group\ 114\ \(2\).svg) no-repeat;
    height: 380px;
    width: 380px;
    z-index: 10;
    border-radius: 50%;
}

.sprite__promo {
    position: absolute;
    top: 119px;
    left: 55px;
    background: url(/src/img/Group\ 102\ \(1\).svg) no-repeat;
    height: 290px;
    width: 290px;
    z-index: 10;
}

.sprite__promo__hand {
    position: absolute;
    background: url(/src/img/Group\ 104\ \(1\).svg) no-repeat;
    height: 320px;
    width: 320px;
    z-index: 10;
}

.sprite__common {
    position: absolute;
    top: 126px;
    left: 55px;
    background: url(/src/img/Group\ 105\ \(4\).svg) no-repeat;
    height: 290px;
    width: 290px;
    z-index: 10;
}

.sprite__uncommon {
    position: absolute;
    top: 107px;
    left: 46px;
    background: url(/src/img/Group\ 127.svg) no-repeat;
    height: 343px;
    width: 310px;
    z-index: 10;
}

.sprite__violetta {
    position: absolute;
    top: 102px;
    left: 15px;
    background: url(/src/img/Group\ 123.svg) no-repeat;
    height: 371px;
    width: 372px;
    z-index: 10;

}

.sprite__sunshine {
    position: absolute;
    top: 118px;
    left: 25px;
    background: url(/src/img/Group\ 119\ \(5\).svg) no-repeat;
    height: 350px;
    width: 350px;
    z-index: 10;

}


.contentBoxSpinners {
    align-items: center;
}


/* --comment- */
.container__blur {
    position: relative;
    display: inline-block;
    font-size: 16px;
    height: 658px;
    margin-bottom: 30px;
    margin-right: 32px;

}

.first__infom {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: 149px;
    top: 83px;
    height: 50px;
    width: 130px;
    z-index: 9999;
    box-sizing: border-box;
    color: #ffffff;
    text-align: start;
    line-height: 10px;
    font-size: 16px;
    padding: 6px 8px 2px 8px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    visibility: hidden;
}

.second__infom {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: 149px;
    top: 122px;
    height: 60px;
    width: 130px;
    z-index: 9999;
    box-sizing: border-box;
    text-align: start;
    color: #ffffff;
    line-height: 10px;
    font-size: 16px;
    padding: 6px 8px 2px 8px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    visibility: hidden;
}

.info__block__left {
    position: relative;
    height: 69px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.btn {
    line-height: 31px;
    background: rgba(0, 0, 0, 0.3);
}

.stor__infom {
    pointer-events: none;
    display: flex;
    opacity: 0;
    position: absolute;
    right: 113px;
    z-index: 9999;
    top: 113px;
    height: 50px;
    width: 130px;
    line-height: 10px;
    font-size: 16px;
    padding: 6px 8px 2px 8px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    text-align: left;
    visibility: hidden;
}





.speed__infom {
    opacity: 0;
    position: absolute;
    right: 86px;
    top: 0px;
    height: 70px;
    width: 130px;
    z-index: 9999;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 10px;
    font-size: 16px;
    padding: 6px 8px 2px 8px;
    text-align: start;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    font-family: 'Dongle';
    font-style: normal;
    font-weight: 300;
    visibility: hidden;
}

.deactivSpin__infom {
    opacity: 0;
    position: absolute;
    right: -24px;
    top: -20px;
    height: 27px;
    width: 101px;
    z-index: 9999;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 10px;
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    text-align: start;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    font-family: 'Dongle';
    font-style: normal;
    font-weight: 300;
    visibility: hidden;
}

.noVip__infom,
.vip__infom {
    opacity: 0;
    position: absolute;
    right: 84px;
    top: -30px;
    height: 37px;
    width: 138px;
    z-index: 9999;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 10px;
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    text-align: start;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    font-family: 'Dongle';
    font-style: normal;
    font-weight: 300;
    visibility: hidden;
}




.one__infom,
.two__infom,
.three__infom,
.fore__infom,
.five__infom {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    height: 40px;
    width: 100px;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 10px;
    font-size: 16px;
    padding: 6px 8px 2px 8px;
    text-align: start;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    font-family: 'Dongle';
    font-style: normal;
    font-weight: 300;
    z-index: 9999;
    visibility: hidden;
    display: flex;
}

.show {
    opacity: 1;
    visibility: visible;
    transition: 0.1s ease-in-out;
    transition-delay: 0.1s;

}

.five__infom {
    right: 46px;
    top: 32px;

}

.fore__infom {
    right: 70px;
    top: 32px;
}

.three__infom {
    right: 95px;
    top: 32px;

}

.two__infom {
    right: 120px;
    top: 32px;

}

.one__infom {
    right: 146px;
    top: 32px;
}

.content__spinner {
    position: relative;
}

/* frostee */
.sprite__top {
    top: 41px;
    left: -31px;
    position: absolute;
    background: url(/src/img/Group\ 115\ \(1\).svg);
    width: 474px;
    height: 471px;
    animation-name: scale-frosty;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.76, -0.06, 0.08, 1.13);
    transform-origin: center;
    overflow: hidden;
    z-index: 11;
}

@keyframes scale-frosty {
    0% {
        transform: scale(0.7);

    }

    50% {
        transform: scale(1.1);
        animation-delay: 2s;
    }

    100% {
        transform: scale(0.7);

    }
}

.sprite__bottom {
    top: -58px;
    left: -132px;
    position: absolute;
    background: url(/src/img/Group\ 117\ \(1\).svg);
    width: 668px;
    height: 669px;
    z-index: 11;
    animation-name: scale-frosty-bottom;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.76, -0.06, 0.08, 1.13);
    transform-origin: center;
    overflow: hidden;

}

@keyframes scale-frosty-bottom {
    0% {
        transform: scale(1.1);

    }

    50% {
        transform: scale(0.7);
        animation-delay: 2s;
    }

    100% {
        transform: scale(1.1);

    }
}

.anim_f {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: -4px;
    height: 555px;
    width: 394px;
    z-index: 11;
    overflow: hidden;
}

.anim_v {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 579px;
    width: 407px;
    z-index: 11;
}

/* ---- */

@keyframes circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes inner-circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.block__anim {
    position: absolute;
    top: 0px;
    left: -12px;
    height: 1px;
    width: 1px;
    z-index: 9;
}

/* spin */

#promo__0.timeActive,
#epic__4.timeActive,
#common__1.timeActive,
#uncommon__2.timeActive,
#rare__3.timeActive,
#sunshine__5.timeActive {
    box-shadow: none;
    animation: none;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    cursor: default;

}

#promo__0.timeActive:hover,
#epic__4.timeActive:hover,
#common__1.timeActive:hover,
#uncommon__2.timeActive:hover,
#rare__3.timeActive:hover,
#sunshine__5.timeActive:hover {
    box-shadow: none;
    animation: none;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));


}

#rare__3.timeActive:disabled,
#rare__3.timeActive[disabled] {
    box-shadow: none;
    animation: none;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
}


.sprite-1 {
    pointer-events: none;
    animation: inner-circle 8s linear infinite;
    filter: blur(0.1px);
}

.sprite-2 {
    pointer-events: none;
    animation: inner-circle 1.5s linear infinite;
    filter: blur(1px);
}


/*  новые спиннеры */
/* комонка */
.commonHead__1 {
    pointer-events: none;
    position: absolute;
    top: 74px;
    right: -8px;
    z-index: 100;
}

.commonBody__1 {
    width: 400px;
    height: 400px;
    pointer-events: none;
    position: absolute;
    z-index: 99;
    left: -11px;
    top: 77px;

}

.sprite-3 {
    pointer-events: none;
    animation: inner-circle 1.2s linear infinite;
    filter: blur(1.5px);
}

.sprite-4 {
    pointer-events: none;
    animation: inner-circle 0.3s linear infinite;
    filter: blur(3px);
}

.sprite-5 {
    pointer-events: none;
    animation: inner-circle 0.5s linear infinite;
    filter: blur(6px);
}

/* violetta */
.sprite__violetta__top__1 {

    background: url(/src/img/Group\ 141\ \(3\).svg);
    background-repeat: no-repeat;
    width: 299px;
    height: 245px;
    top: 170px;
    left: 52px;
    animation-duration: 1.5s;
    animation-delay: 0.2s;
}

.sprite__violetta__top__2 {

    background: url(/src/img/Group\ 148\ \(2\).svg);
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    top: 80px;
    left: -3px;
    width: 417px;
    height: 417px;
    animation-duration: 1.5s;
    animation-delay: 0.3s;



}

.sprite__violetta__top__3 {

    background: url(/src/img/Group\ 149\ \(2\).svg);
    background-repeat: no-repeat;
    top: 124px;
    left: 41px;
    width: 327px;
    height: 321px;
    animation-duration: 1.5s;
    animation-delay: 0.4s;


}

.sprite__violetta__top__4 {

    background: url(/src/img/Group\ 147\ \(1\).svg);
    background-repeat: no-repeat;
    top: 97px;
    left: 6px;
    width: 385px;
    height: 378px;
    animation-duration: 1.5s;
    animation-delay: 0.5s;

}

.sprite__violetta__top__1,
.sprite__violetta__top__2,
.sprite__violetta__top__3,
.sprite__violetta__top__4 {
    pointer-events: none;
    position: absolute;
    animation-name: scale-violetta-rotate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.92, 0.59, 0, 1.01);
    transform-origin: center;
    z-index: 99;
}


@keyframes scale-violetta-rotate {
    0% {
        transform: scale(0.8);

    }

    50% {
        transform: scale(1);


    }

    100% {
        transform: scale(0.8);


    }
}

.sprite__violetta__bottom {
    pointer-events: none;
    background: url(/src/img/Group\ 125\ \(4\).svg);
    background-repeat: no-repeat;
    width: 186px;
    height: 186px;
    position: absolute;
    top: 193px;
    left: 108px;
    animation: move-violetta-circle 14s linear infinite;
    z-index: 99;
}

@keyframes move-violetta-circle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* sunshine */
.sprite__sunshine__top {
    pointer-events: none;
    background: url(/src/img/Group\ 121\ \(4\).svg);
    background-repeat: no-repeat;
    width: 234px;
    height: 236px;
    position: absolute;
    top: 178px;
    left: 78px;
    animation: move-in-circle 12s linear infinite;
    z-index: 11;

}

@keyframes move-in-circle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* promo */



.sprite__promo__hand {
    top: 275px;
    left: 206px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(0deg);
    transform: rotate(0deg);
    animation: moveCircular 2s cubic-bezier(0, 0.56, 0.58, 1) infinite;
    animation-fill-mode: both;

}

@keyframes moveCircular {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) rotate(45deg);
        animation-delay: 1.5s;
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

/* ----- */
/* common */
.sprite__common__hand {
    pointer-events: none;
    height: 292px;
    width: 290px;
    z-index: 10;
    background: url(/src/img/Group\ 106\ \(1\).svg);
    top: 278px;
    left: 198px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(0deg);
    transform: rotate(0deg);
    animation: moveСommon 1.5s ease-in-out infinite;
    animation-delay: 1.5s;
    animation-fill-mode: both;
}

@keyframes moveСommon {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

/* ----- */
/* uncommon */
.sprite__uncommon__hand {
    pointer-events: none;
    background: url(/src/img/Group\ 108\ \(1\).svg) no-repeat;
    height: 212px;
    width: 255px;
    z-index: 10;
    top: 283px;
    left: 197px;
    position: absolute;
    animation: moveUncommon 2s cubic-bezier(0, 0.56, 0.58, 1) infinite;

}

@keyframes moveUncommon {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    50% {
        transform: translate(-50%, -50%) rotate(-65deg);
        animation-delay: 1.5s;
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
        animation-delay: 1.5s;
    }

}


.sprite__uncommon__top {
    opacity: 0;
    transform: scale(0.1);
    animation: sprite__uncommon__top-animation 1s ease-in-out infinite;

}

.sprite__uncommon__top {
    top: 27px;
    left: -52px;
    position: absolute;
    background: url(/src/img/Group\ 113\ \(1\).svg);
    width: 500px;
    height: 500px;
    z-index: 99;
}


@keyframes sprite__uncommon__top-animation {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    50% {
        opacity: 1;
        /* значения, когда объект полностью появится и достигнет максимального масштаба */
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
        /* значения, когда объект исчезнет и вернется к начальному масштабу */
    }
}

/* ---- */
.head {
    position: absolute;
    top: 183px;
    left: 92px;
    background: url(/src/img/Head.svg) no-repeat;
    height: 163px;
    width: 189px;
    z-index: 12;
}

.head__magma {
    position: absolute;
    top: 183px;
    left: 122px;
    background: url(/src/img/Group\ 133\ \(1\).svg) no-repeat;
    height: 155px;
    width: 141px;
    z-index: 12;
}

.head__common {
    position: absolute;
    top: 183px;
    left: 120px;
    background: url(/src/img/Group\ 132\ \(2\).svg) no-repeat;
    height: 161px;
    width: 132px;
    z-index: 12;
}

.head__uncommon {
    position: absolute;
    top: 183px;
    left: 113px;
    background: url(/src/img/Group\ 206\ \(8\).svg) no-repeat;
    height: 162px;
    width: 150px;
    z-index: 12;
}

.head__violetta {
    position: absolute;
    top: 183px;
    left: 90px;
    background: url(/src/img/Group\ 120\ \(2\).svg) no-repeat;
    height: 174px;
    width: 190px;
    z-index: 12;
}

.head__sunshine {
    position: absolute;
    top: 180px;
    left: 88px;
    background: url(/src/img/Group\ 118\ \(3\).svg) no-repeat;
    height: 196px;
    width: 190px;
    z-index: 12;
}

/* new anim common */
.comn__1 {
    position: absolute;
    top: 127px;
    left: 40px;
    width: 300px;
    height: 300px;
}

.headCommon__1 {
    position: absolute;
    z-index: 10;
    left: 39px;

}

.commonFon__1 {
    position: fixed;
    top: 194px;
    right: 30px;
}


.stopAnimation__0 {
    animation: none !important;

}

.stopAnimation__1 {
    transform: translate(-50%, -50%) rotate(0deg);
    animation: none !important;
}

.bottom__menu button:first-child.StopClaimDis:hover,
.bottom__menu .TimeStartBtn:hover .bottom__menu .StopClaimDis:hover {
    box-shadow: none;
    animation: none;
    background: rgba(0, 0, 0, .2);
    cursor: default;
}

.StopClaimDis a {
    cursor: default;
}

.bottom__menu .StopClaimDis {
    box-shadow: none;
    animation: none;
    background: rgba(0, 0, 0, .2);
    cursor: default;
    line-height: 10px;
}

.runa__spn,
.spin__spn {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 92px;
    bottom: -38px;
}
.runa__spn p,
.spin__spn p{
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 0.6;
    font-weight: 400;
}




.runa__spn p:first-child,
.spin__spn p:first-child {
    font-size: 32px;
    height: 27px;
    font-weight: 400;
}

.runa__spn p:last-child,
.spin__spn p:last-child {
    margin-top: 36px;
    margin-left: 4px;

}
.jingles{
    background: rgba(0, 0, 0, .2);
    border-radius: 15px;
    height: 262px;
    width: 168px;
    position: fixed;
    height: 262px;
    width: 168px;
    left: 107px;
    top: 330px;
    z-index: 2;
}
#violetta__runaBg,
#sunshine__runaBg,
#uncommon__runaBg,
#common__runaBg,
#promo__runaBg,
#rare__runaBg {
    position: fixed;
    height: 262px;
    width: 168px;
    left: 107px;
    top: 330px;
    z-index: 2;
}

#promo__runaBg {
    background: url(../../../img/04\ Rare\ Rune\ Effect.webp) no-repeat;

}

#common__runaBg {
    background: url(../../../img/02\ Common\ Rune\ Effect.webp) no-repeat;

}

#uncommon__runaBg {
    background: url(../../../img/03\ Uncommon\ Rune\ Effect.webp) no-repeat;

}

#sunshine__runaBg {
    background: url(../../../img/06\ Legend\ Rune\ Effect.webp) no-repeat;

}

#violetta__runaBg {
    background: url(../../../img/05\ Epic\ Rune\ Effect\ \(1\).webp) no-repeat;

}

#rare__runaBg {
    background: url(../../../img/04\ Rare\ Rune\ Effect.webp) no-repeat;

}



.disabled:hover {
    cursor: default;
}



.bottom__menu button .TimeStartBtn,
.bottom__menu button .StopClaimDis {
    background: rgba(0, 0, 0, .2);
    width: 152px;
    border-radius: 15px;
    height: 30px;
    cursor: default;
    animation: none;
    box-shadow: none;
    pointer-events: none;
}

.StopClaimDis {
    margin-left: 22px;
    cursor: default;
}


.bottom__menu .TimeStartBtn,
.bottom__menu .StopClaimDis {
    background: rgba(0, 0, 0, .2);
    width: 152px;
    border-radius: 15px;
    height: 30px;
    cursor: default;
    animation: none;
    box-shadow: none;

}



.bottom__menu button:last-child.TimeStartBtn {
    background: rgba(0, 0, 0, .2);
    animation: none;
    box-shadow: none;
}

#promo__0.btn.TimeStartBtn,
#sunshine__5.btn.TimeStartBtn,
#rare__3.btn.TimeStartBtn,
#common__1.btn.TimeStartBtn,
#uncommon__2.btn.TimeStartBtn,
#epic__4.btn.TimeStartBtn {
    background: rgba(0, 0, 0, .2);
    animation: none;
    box-shadow: none;
    pointer-events: none;
}

.StopClaimDis {
    margin-left: 22px;
}

.commonBody__1,
.commonHead__1,
canvas,
canvas,
.commonBody__1 img,
.commonHead__1 img {
  width: 400px;
  height: 400px;
}

.activ__ether,
.block__ether {
    right: 39px;
}


.bgProgSpeed {
    width: 183px;
    overflow: hidden;
}

/* soul */
.soulPromo,
.soulCommon,
.soulUncom,
.soulRare,
.soulViolet,
.soulLegend {
    position: absolute;
    bottom: 28px;
    right: 37px;
    width: 96px;
    height: 96px;
}
.soulPromo{
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}
.soulCommon {
    background: url(../../../img/Soul-Animation-common.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    
}
.soulUncom {
    background: url(../../../img/Soul-Animation-Uncommon.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}
.soulRare {
    background: url(../../../img/Soul-Animation-Rare.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}
.soulViolet {
    background: url(../../../img/Soul-Animation7.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}
.soulLegend {
    background: url(../../../img/Soul-Animation-Legend.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

}

.burnSpinney,
.soulClose  {
    position: absolute;
    bottom: 11px;
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 50%;
    cursor: pointer;

}
.burnSpinney {
    background: url(../../../img/ButtonSoul\ \(1\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    left: 28px;
}

.soulClose {
    background: url(../../../img/ButtonSoul.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    right: 28px;
 
}

.boxExpired {
    width: 96px;
    height: 96px;
    opacity: 0.5;
}
.burnSpinney:hover{
    background: url(../../../img/ButtonS\ \(2\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 34px;
    height: 34px;
}
.soulClose:hover{
    background: url(../../../img/ButtonS\ \(3\).webp);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 34px;
    height: 34px;
}

@media (max-width: 789px) {
    .container__blur {
        margin-right: 0;
    }
       
      

}


@media (max-width: 610px) {

    .soulPromo, .soulCommon, .soulUncom, .soulRare, .soulViolet, .soulLegend{
        width: 120px;
        height: 120px;
        right: 3px;
    }

    .fonCardSpinner{
        width: 320px;
    }

    .infoPapBlock {
        width: 94px;
        margin: 12px 6px 12px 5px;
    }

    #runa_promo__0,
    #runa_common__1,
    #runa_rare__2,
    #runa_epic__3,
    #runa_uncomon__4,
    #runa_legend__5 {
    height: 90px;
    width: 90px;
    position: absolute;
    left: -11px;
    top: -12px;
    z-index: 3;
 
}

    .papDay, .titlePap {
        width: 28px;
    }

    .titleDay {
        font-size: 13px;
        margin: 2px 0px 0px 0px;
    }
  
 
    .commonBody__1,
    .commonHead__1,
    canvas,
    canvas,
    .commonBody__1 img,
    .commonHead__1 img {
      width: 300px;
      height: 300px;
    }


    #item:not(:last-of-type) {
        margin-right: 2px;
    }

    #Bank_1 {
        margin-top: 40px;
    }

    .craft__right__content .title {
        right: 20px;
    }

    .unstake__run {
        top: 410px;
        left: 177px;
    }



    .foo-bar.Toastify__toast-theme--light {
        position: fixed;
        right: 0;
    }

    .top__menu__name,
    .bottom__menu {
        width: 300px;
    }

    .slider__container {
        width: 300px;
    }

    .craft__card__slider {
        width: 253px;
    }

    #e .nft__card__content {
        width: 300px;
    }

    .craft__card__slider {
        left: 27px;
    }

    .soulPromo,
    .soulCommon,
    .soulUncom,
    .soulRare,
    .soulViolet,
    .soulLegend {
        width: 86px;
        height: 86px;
        right: 26px;
        bottom: 22px;
        pointer-events: none;
    }
 
    .soulClose,
    .burnSpinney{
        bottom: 2px;
        z-index: 9;
    }
    .soulClose {
        right: 30px;
    }
    .burnSpinney {
        left: 30px;
    }

    .burnSpinney, .soulClose{
        width: 29px;
        height: 29px;
    }
    .bottom__menu__chek,
    .bottom__menu__chek__game {
        margin-left: 0;
    }

    #bt {
        right: 11px;
    }

    .card__player__blue {
        width: 320px;

        position: relative;
        border-radius: 10px;
    }

    button#item.card__player__btn a {
        font-size: 16px;
    }

    .winterFonBtn {
        width: 126px;
        bottom: 26px;
        right: 23px;
    }
    .fonCardLights {
        width: 320px;
    }


    #violetta__runaBg,
    #sunshine__runaBg,
    #uncommon__runaBg,
    #common__runaBg,
    #promo__runaBg,
    #rare__runaBg {
        position: absolute;
        height: 228px;
        width: 134px;
        left: 92px;
        top: 223px;
        z-index: 2;
       
    }

    #promo__runaBg {
        background: url(../../../img/04\ Rare\ Rune\ Effect.webp) no-repeat;

    }
    #common__runaBg {
        background: url(../../../img/02\ Common\ Rune\ Effect\ 1.webp) no-repeat;
    }

    #uncommon__runaBg {
        background: url(../../../img/03\ Uncommon\ Rune\ Effect\ 1.webp) no-repeat;
    }

    #sunshine__runaBg {
        background: url(../../../img/06\ Legend\ Rune\ Effect\ 1.webp) no-repeat;
    }

    #violetta__runaBg {
        background: url(../../../img/05\ Epic\ Rune\ Effect\ \(1\)\ 1.webp) no-repeat;
    }

    #rare__runaBg {
        background: url(../../../img/04\ Rare\ Rune\ Effect\ 1.webp) no-repeat;
    }

    .container__blur .top__menu__name {
        width: 320px;
    }

    .container__blur .content__spinner .bottom__menu {
        width: 320px;

    }

    .bottom__menu button:first-child a {
        line-height: 9px;
        font-size: 16px;
    }

    .bottom__menu button:first-child {
        line-height: 5px;
        font-size: 16px;
    }

    .bottom__menu .btn {
        font-size: 16px;
        line-height: 26px;
    }

    .fon__left {
        right: 24px;
    }

    .fon__right {
        left: 24px;
    }
    #sunshine_runa__5,
    #violetta_runa__4,
    #frosty_runa__3,
    #uncommon_runa__2,
    #promo_item__0,
    #common_item__1,
    #uncommon_item__2,
    #rare_item__3,
    #epic_item__4,
    #jing_item__6,
    #legend_item__5,
    #promo_runa__0, 
    #common_runa__1 {
        background-size: 70%;
    }
    
 
    .fon__left__button{
        right: 28px;
    }
    .fon__right__button {
       left: 28px;
    }

    .fon__left__button a,
    .fon__right__button a {
        font-size: 16px;
        line-height: 23px;
    }

    .speed {
        font-size: 16px;
        line-height: 23px;
    }

    .progressbar {
        width: 154px;
        height: 7px;
    }

    .second,
    .first {

        height: 25px;
        font-size: 16px;
    }

    .second {
        margin-top: 8px;
    }

    .info__block__left div {
        width: 103px;
        height: 25px;

    }

    .info__block__left {

        height: 58px;


    }

    .info__block__right {
        width: 154px;
        height: 58px;
    }

    .info__block__left div {
        line-height: 29px;
    }

    .first span:first-child,
    .second span:first-child {
        line-height: 23px;
    }

    .title__s {
        font-size: 24px;
        top: 27px;
    }

    .icon__fonStor {
        background: url(../../../img/coinStor.webp) no-repeat;
        background-size: 100%;
        top: -7px;
        right: -6px;
    }

    .runa__spn p:first-child,
    .spin__spn p:first-child {
        font-size: 24px;
    }

    .runa__spn p:last-child,
    .spin__spn p:last-child {
        font-size: 16px;
        margin-top: 31px;
    }

    /* .runa__spn, .spin__spn {
        bottom: -25px;
    }
     */



    /*     
    .runa__spn{ 
        left: 240px;
        
    } */



    .container__blur .bottom__menu button {
        font-size: 16px;

    }

    .container__blur .bottom__menu button {
        width: 128px;
        height: 26px;
    }

    .card__player__block {
        display: flex;
        height: 18px;
        margin: 15px 16px 0 30px;
        width: 104px;
    }

    #item {
        width: 18px;
        height: 18px;

    }


    .info__block {
        height: 58px;
        margin: 8px 24px 0px 24px;
    }



    .card__player__blue {
        height: 456px;
    }

    .item__spiner__runa{
        left: 4px;
        top: 1px;
    }
    .item__spiner {
        left: 5px;
        top: 0px;
    }

    #runa,
    .runa {
        background: var(--dark-elements, rgba(0, 0, 0, 0.20));
        width: 84px;
        height: 84px;
        border-radius: 50px;
        left: 118px;
    }

    .rare__noactiv,
    .runa__noactiv,
    .promo__noactiv,
    .common__noactiv,
    .uncommon__noactiv,
    .violetta__noactiv,
    .sunshine__noactiv {
        width: 30px;
        height: 30px;
        left: 145px;
        top: 377px;

    }

    .rare__noactiv {
        background: url(../../../img/rarMob.webp) no-repeat;
    }


    /* --------------- */

    .promo__noactiv {
        background: url(../../../img/prom.webp) no-repeat;
    }

    .common__noactiv {
        background: url(../../../img/commonMob.webp) no-repeat;
    }

    .uncommon__noactiv {
        background: url(../../../img/uncom.webp) no-repeat;
    }

    .violetta__noactiv {
        background: url(../../../img/violetMob.webp) no-repeat;

    }

    .sunshine__noactiv {
        background: url(../../../img/sunshMob.webp) no-repeat;
    }

    .commonBody__1,
    .commonHead__1 {
        width: 300px;
        left: 10px;
     
    }

    .container__blur {
        height: 570px;
        margin-right: 0;
    }
    .expired {
        bottom: 44px;
        left: 34px;
        width: 65px;
       
    }

    #runa_promo__0,
    #runa_common__1,
    #runa_rare__2,
    #runa_epic__3,
    #runa_uncomon__4,
    #runa_legend__5 {
        left: -4px;
        top: -2px;
    }

    .runa__noactiv:hover,
    .promo__noactiv:hover,
    .common__noactiv:hover,
    .uncommon__noactiv:hover,
    .violetta__noactiv:hover,
    .sunshine__noactiv:hover {
        background-image: url(/src/img/activMob.webp);
        background-repeat: no-repeat;
        position: absolute;
        z-index: 10;
        height: 30px;
        width: 30px;
        left: 145px;
        top: 377px;
        border-radius: 50%;
    }
    .card__player__btn .SpinSpid {

        height: 20px;
    }
     
    .card__player {
        width: 300px;
        margin-left: 13px;
      
    }

    .card__player__block {
        margin: 15px 14px 0 20px;
     
    }

    .progressbar {
        width: 145px;
    }

    .progressSpeed {
        width: 140px;
    }
    #progress-bar {
        width: 140px;
    }
    .bgProgSpeed {
        width: 145px;
        overflow: hidden;
    }
    .progress__fon {
        width: 145px;
    }

    .param {
        line-height: 11px;
    }
 

    .winter_top {
        background: url(../../../img/winter4.webp);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 335px;
        left: -5px;
    }


}

@media (max-width: 425px) {
    .container__blur:not(:last-child) {
        margin-right: 0;
    }

  

   
}