.modalRuna {
  height: 100%;
  width: 100%;
  background: rgba(46, 51, 65, 0.5);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  z-index: 999;


}



.burnLoaderBg {
  width: 282px;
  height: 24px;
  box-shadow: 0px 0px 11.6928px #F18C1E, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;
}
.modalRuna.activerun {
  opacity: 1;
  pointer-events: all;

}

.modal__contentRuna {
  background: var(--dark-elements, rgba(0, 0, 0, 0.20));
  border-radius: 10px;
  width: 540px;
  height: 370px;
  transition: 0.4s all;

}



.modal__contentRuna.activerun {
  transform: scaleX(1);


}

.stakeRuna,
.cancelRuna {
  width: 125px;
  height: 24px;
  background: var(--20-dark, rgba(0, 0, 0, 0.20));
  border-radius: 20px;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  line-height: 25px;
}

.cancelRuna {
  margin-right: 12px;

}
.icon__modalRegister,
.icon__modalRuna {
  background: url(../../img/Group\ 93.svg);
  background-repeat: no-repeat;
  height: 24px;
  width: 27px;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;

}
.icon__modalRegister{
  margin-top: 24px;
}

.title__modalRuna {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
  line-height: 19px;

}

.parag__modalRuna {
  color: #9AA0B0;
  text-align: center;
  line-height: 16px;

}
.icon__modalRegister {
  background: url(../../img/Group\ 93.webp);
  background-repeat: no-repeat;
  height: 24px;
  width: 27px;
}

.btn__runs {
  display: flex;
  justify-content: center;
  width: 262px;
  margin: 0 auto;

}



/* slider */
.slide__1Prem.selected,
.slide__player_1.selected,
.slide__player_2.selected,
.slide__player_3.selected,
.slide__player_4.selected,
.slide__player_5.selected,
.slide__player_6.selected,
.slide__1.selected,
.slide__2.selected,
.slide__3.selected,
.slide__4.selected,
.slide__5.selected,
.slide__6.selected {
  box-shadow: 0px 0px 11.6928px #F18C1E, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;
  border-radius: 9px;
  transition: 0.8s;

}
.bornTicket 
.bg__id__runs,
#modal7
.bg__id__runs,
#modal17
.bg__id__runs,
#modal6
.bg__id__runs,
#modal14
.bg__id__runs,
#modal4
.bg__id__runs,
#modal3
.bg__id__runs {
  position: absolute;
  width: 100px;
  height: 16px;
  background: var(--glass-card, rgba(35, 38, 48, 0.50));
  backdrop-filter: blur(25px);
  border-radius: 5px 5px 0px 0px;
}

.id__runs {
  position: absolute;
  top: 8px;
  left: 9px;
}

#runs__slider {
  width: 424px;
  height: 179px;
  margin-top: 7px;
  margin-bottom: 24px;
  padding-right:  12px;
  padding-left: 12px;
}


#spinn__slider {
  margin: 0 auto;
  width: 436px;
  height: 179px;
  margin-bottom: 24px;
  padding-left: 10px;
  padding-right: 7px;
}


.slide__1 {
  background-image: url(../../img/01\ Prommo\ Rune.webp);
  background-repeat: no-repeat;
}
.slide__1Prem {
  background-image: url(../../img//Basic\ Premium\ access.webp);
  background-repeat: no-repeat;
}

.gold__player {
  background-image: url(../../../src/img/elitPrem.webp);
  background-size: 100%;
}

.slide__2 {
  background-image: url(../../img/newCommon\ Rune.webp);
  background-repeat: no-repeat;
}

.slide__3 {
  background-image: url(../../img/newUncommon\ Rune.webp);
  background-repeat: no-repeat;
}

.slide__4 {
  background-image: url(../../img/newRare\ Rune.webp);
  background-repeat: no-repeat;
}

.slide__5 {
  background-image: url(../../img/newEpic\ Rune.webp);
  background-repeat: no-repeat;
}

.slide__6 {
  background-image: url(../../img/newLegend\ Rune.webp);
  background-repeat: no-repeat;
}
.swiper-slide, .swiper-slide {
  width: 100px;
}

/* .slide__1 {
  background-image: url(../../img/01\ Magmius.svg);
  background-repeat: no-repeat;
  width: 100px;height: 140px;
} */

/* spinney */
.slide__1Prem,
.slide__1,
.slide__2,
.slide__3,
.slide__4,
.slide__5,
.slide__6,
.slide__player_1,
.slide__player_2,
.slide__player_3,
.slide__player_4,
.slide__player_5,
.slide__player_6,
.slide__player_7 {
  width: 100px;
  height: 140px;
  margin: 13px 0px 8px 0px;
  border-radius: 9px;
  background-size: 100%;
  cursor: pointer;
}


.slide__player_1 {
  background-image: url(../../img/Magmius.webp);
  background-repeat: no-repeat;

}

.slide__player_2 {
  background-image: url(../../img/newSteelade\ \(2\).webp);
  background-size: 100%;
  background-repeat: no-repeat;
  
}

.slide__player_3 {
  background-image: url(../../img/newLeaflynn2\ \(1\).webp);
  background-size: 100%;
  background-repeat: no-repeat;

}

.slide__player_4 {
  background-image: url(../../img/newFrostee\ \(2\).webp);
  background-size: 100%;
  background-repeat: no-repeat;

}

.slide__player_5 {
  background-image: url(../../img/Violetta.webp);
  background-repeat: no-repeat;

}

.slide__player_6 {
  background-image: url(../../img/Sunshine.webp);
  background-repeat: no-repeat;

}
.slide__player_7 {
  background-image: url(../../img/01\ Jingles.webp);
  background-repeat: no-repeat;

}

#sp {
  line-height: 24px;
}

.slots__block {
  display: flex;
  justify-content: center;

}
#modal3 .modal__contentRuna {
  width: 540px;
  height: 541px
}

#modal3 .parag__modalRuna {
  width: 306px;
  margin: 0 auto;
}
#modal3 .parag__modalRuna:not(:last-child){
  margin-bottom: 16px;
}

.slots {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 26px;
  width: 68px;
  height: 20px;
  line-height: 25px;
  margin-top: 2px;
  margin-left: 16px;
  text-align: center;
  color: #fff;
  line-height: 21px;
}

.stakeRuna.disabled {
  pointer-events: none;

}

.SwiperSlide  {
  width: 100px;
}
#burnRuns {
  margin-left: 24px;
}
#modal3 .firstPrag{
  margin-top: 7px;
}
.burnRunsCateg {
  display: flex;
  flex-direction: column-reverse;
  width: 300px;
  margin: 0 auto;
}



.burnRunsCateg .icon__spn p {
  line-height: 29px;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 20px;
}

#burnRuns .winnerLoaderBg{
  width: 282px;
  border-radius: 15px;
}

#burnRuns .icon_bornNft {
    background-repeat: no-repeat;
    height: 24px;
    width: 27px;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;
}

.burnRunsCateg .fonSpn:not(:last-child) {
  margin-top: 4px;


}
#burnRuns .parag__modalRuna {
  width: 435px;
  margin-top: 7px;
}
#burnRuns #runs__slider {
  margin-top: 22px;
}

@media (max-width: 768px) { 

  
#burnRuns .winnerLoaderBg{
  width: 258px;
  
}

  #burnRuns .parag__modalRuna {
    width: 306px;
  }
  #modal3{
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
  }
  #burnRuns {
    margin-top: 24px;
    margin-left:0px;
  }
  #modal3 .btn__runs {
    padding-top: 20px;
  }
 
  #modal3 .modal__contentRuna {
    width: 340px;
  }
  #modal4 .swiper-wrapper,
  #modal3 .swiper-wrapper {
    margin-top: 10px;
  }
  #spinn__slider,
  #runs__slider {
    width: 100px;
    height: 179px;
    margin-bottom: 5px;
    padding-right: 0;
    padding-left: 0;
  }
  
#modal17 #spinn__slider {
  width: 138px;
  height: 259px;
}

.burnLoaderBg {
  width: 276px;
  
}
  .id__runs {
    left: 9px;
  }
}