.modal__content {
    height: 100%;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(46, 51, 65, 0.5);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    z-index: 9998;
}

.modal-overlay {
    background: rgba(46, 51, 65, 0.5);
    backdrop-filter: blur(10px);
}

.LogiContent {
    /* position: fixed; */
    opacity: 1;
}

.wallet__price {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 200px;
    height: 50px;
}

.modal {
    width: 492px;
    height: 256px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container__modal {
    display: flex;
}

.cintainer__purchase {
    width: 263px;
    margin-left: 27px;

}

.modal__content .wallet__content {
    margin: 0 auto;
}


.icon__modal {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -46px;
    right: -84px;
    /* margin: 14px 0px 10px 0px ; */

}

.price__icon {
    background: url(../../img/icon__purchase.svg) no-repeat;
    width: 30px;
    height: 20px;
    position: absolute;
    z-index: 5;
    top: 15px;
    left: 10px;
}

.icon__spn__p p {
    position: absolute;
    top: -41px;
    right: -26px;
}

.icon__card__Violetta,
.icon__card__Sunshine,
.icon__card__Legend,
.icon__card__Epic,
.icon__card__Rare,
.icon__card__Frostee,
.icon__card__Uncommon,
.icon__card__Leaflynn,
.icon__card__Common,
.icon__card__Steelade,
.icon__card__Promo,
.icon__card__Jing,
.icon__card__Magmus,
.icon__card__spinny {
    background-image: url(../../img/newVioletta\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 164px;
    height: 230px;
}

.cintainer__purchase .block__sum {
    background: none;
    box-shadow: none;
}

.price {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 40px;
    width: 16px;
    transform: rotate(180deg);
}

.price p {
    position: absolute;
    left: -7px;
    top: 15px;
    font-size: 16px;
    text-transform: uppercase;
    transform: rotate(90deg);
    color: var(--Text-2, #9AA0B0);
}

#slots .price p {
    top: 20px;
}



/* .CRAFT1 .price p {
    left: -18px;
    top: 20px;
} */



.modal__content.activerun {
    opacity: 1;
    pointer-events: all;

}

.modal.activerun {
    transform: scaleX(1);
}

.btn__modal {
    position: absolute;
    bottom: 21px;
}


.btn__modal button {
    width: 125px;
    height: 24px;
    border: none;
    background: var(--20-dark, rgba(0, 0, 0, 0.20));
    border-radius: 20px;
    margin-top: 12px;
    line-height: 24px;
}

#modal16 .btn__modal button {
    line-height: 4px
}

.btn__modal button:first-child {
    margin-right: 12px;
}

.cancelRuna:hover,
.btn__modal .unruna:last-child:hover,
.btn__modal button:first-child:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.stakeRuna:hover,
.btn__modal .unruna:last-child:hover,
.btn__modal button:last-child:hover {
    background: var(--important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.swiper-slide {
    cursor: pointer;
}

.cintainer__purchase .icon__spn {

    position: absolute;
    background: none;
}

.q {
    position: absolute;
    bottom: 50px;
    right: 19px;
    width: 263px;
    height: 60px;
    line-height: 16px;
    text-align: center;
    color: #9AA0B0;
}

.title_purchase {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
}

.p {
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.modal__content .wallet__content {
    display: flex;
    width: 200px;
    height: 50px;
    margin-top: 9px;
    background: var(--dark-elements, rgba(0, 0, 0, 0.20));
    border-radius: 5px;
}

.modal__content .wallet__info,
.modal__content .name__wallet {
    margin: 0;

}

.name__wallet.price {
    margin-left: 5px;
    margin-top: 5px;

}

.modal__content .icon__sum p {
    font-size: 36px;
    font-weight: 400;
    left: 39px;
    top: -50px;

}

/* unstakeSpinney */

#claim__unstake {
    width: 540px;
    height: 200px;
}

.icon__unstake {
    background: url(../../img/Group\ 98.svg);
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 24px;

}

.u {
    color: #9AA0B0;
    text-align: center;
    line-height: 16px;
}

#modal10 .u {
    width: 414px;
}


.unstake {
    line-height: 20px;
}

.unruna {
    bottom: 18px;
}

.server {
    bottom: 183px;
}

#modal10 .unruna,
#modal11 .unruna {
    bottom: 20px;
}

#modal10 .u,
#modal11 .u {
    margin-top: 12px;
}

.lin_bg {
    position: absolute;
    width: 28px;
    height: 2px;
    background: linear-gradient(180deg, #F08019 0%, #F6CD3A 100%);
    border-radius: 5px;
    margin-top: 10px;
    bottom: 223px;
    right: 137px;
}

.wombat,
.anchor,
.cloudwallet {
    width: 200px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: none;
    cursor: pointer;
    position: relative;
}

.anchor {
    background-image: url(../../img/ankor.svg);
    background-repeat: no-repeat;
    background-position: 18% 55%;
    margin-bottom: 10px;

}

.cloudwallet {
    background-image: url(../../img/Group\ 210.svg);
    background-repeat: no-repeat;
    background-position: 20% 45%;
    margin-bottom: 10px;
}

.wombat {
    background-image: url(../../img/bombat.svg);
    background-repeat: no-repeat;
    background-position: 21% 55%;

}

.title__wallet {
    color: rgb(255, 255, 255);
    top: 33px;
}

.choose__server {
    margin-right: 54px;
    color: rgb(255, 255, 255);
    line-height: 20px;
}

.bottomserv {
    margin-right: 79px;
    margin-top: 34px;

}

#claim__server {
    width: 300px;
    height: 200px;
}

#modal13.modal__content .icon__sum p,
#modal1.modal__content .icon__sum p {
    width: 104px;
}

/*  */
.boxFirstStor {
    width: 492px;
    height: 159px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
}

.boxStor {
    width: 220px;
    height: 60px;
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin: 0 auto;
}

.boxFirstStor .block__sum {
    background: none;
}

.boxMarket {
    width: 492px;
    height: 198px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    border-radius: 10px;
    margin: 8px 0px 8px 0px;
}

.textMarket {
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    line-height: 8px;
    margin-bottom: 16px;
}

.boxStor {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.leftAllStop {
    width: 134px;
    height: 29px;
    margin-right: 16px;
}

.leftAllStop .textMarket {
    color: var(--White, #FFF);
    text-align: center;
    font-size: 16px;
    margin-bottom: 7px;
}

.iconClassTicket {
    background: url(../../img/170-tic.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 164px;
    height: 230px;

}

#spinn__sliderMarket {
    width: 324px;
    height: 144px;
    padding: 12px;
}

#spinn__sliderMarket .swiper-slide {
    width: 100px;
}

#spinn__sliderMarket .swiper-wrapper {
    height: 140px;
}

.slide__Spinney,
.slide__Ticket,
.slide__Runa {
    width: 100px;
    height: 140px;

}

.sliderModalMarket {
    display: flex;
    height: 160px;
    width: 400px;
    margin-bottom: 6px;
}

.spnText,
.storBalance {
    font-size: 36px;
    line-height: 0.6;
    font-weight: 400;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.spnText {
    font-size: 16px;
    margin-top: 2px;
}

.iconTokenStor {
    background: url(../../img/coinx2.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 23px;
    height: 23px;
    margin: 0 auto;
    margin-top: 7px;
}

.rightIconSpn {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    background: var(--Dark-Elements, rgba(0, 0, 0, 0.20));
    margin-right: 6px;
}

#modal13.modal__content .wallet__content,
#modal1.modal__content .wallet__content {
    width: 220px;
    height: 60px;
}

#modal13 .icon__modal,
#modal1 .icon__modal {
    top: -48px;
    right: -97px;
    width: 48px;
    height: 48px;
}

#modal13 .price__icon,
#modal1 .price__icon {
    top: 14px;
}

#modal13.modal__content .icon__sum p,
#modal1.modal__content .icon__sum p {
    left: 52px;
    top: -39px;
    line-height: 0.6;
    font-weight: 400;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#modal13 .icon__spn__p p,
#modal1 .icon__spn__p p {
    top: -41px;
    right: -38px;
    line-height: 0.6;
    font-weight: 400;
    background: var(--Important-elements, linear-gradient(180deg, #F08019 0%, #F6CD3A 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#modal13 .name__wallet.price,
#modal1 .name__wallet.price {
    width: 16px;
    height: 48px;
}

#modal13 .price p,
#modal1 .price p {
    top: 19px;
}

#modal13 .name__wallet.price,
#modal1 .name__wallet.price {
    margin-left: 6px;
    margin-top: 6px;
}

.selected {
    width: 100px;
    height: 140px;
    box-shadow: 0px 0px 11.6928px #F18C1E, 0px 0px 6.6816px #F18C1E, 0px 0px 3.8976px #F18C1E, 0px 0px 1.9488px #F18C1E, 0px 0px 0.5568px #F18C1E, 0px 0px 0.2784px #F18C1E;
    border-radius: 5px;
    transition: 0.8s;
}

/*  */
#claim__server .modal {
    margin-top: 15px;
    justify-content: start;
}

#claim__server .clame__wallet {
    border-radius: 15px;
    margin-top: 0;
}

#claim__server .dropdown__header {
    line-height: 32px;
}


.icon__card__Magmus {
    background: url(../../img/nevmag.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Promo {
    background: url(../../img/newPromo.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Sunshine {
    background: url(../../img/newSunshine\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Jing {
    background: url(../../img/01\ Jingles.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Legend {
    background: url(../../img/newLegend\ Rune.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Epic {
    background: url(../../img/newEpic\ Rune.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Frostee {
    background: url(../../img/newFrostee\ \(2\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

#Pass .icon__modal {
    right: -124px;
}

.icon__card__Rare {
    background: url(../../img/newRare\ Rune.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Uncommon {
    background: url(../../img/newUncommon\ Rune.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

#modal9 #slots .price p {
    top: 15px;
}

.icon__card__Leaflynn {
    background: url(../../img/newLeaflynn2\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Common {
    background: url(../../img/newCommon\ Rune.webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Steelade {
    background: url(../../img/newSteelade\ \(2\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon__card__Violetta {
    background: url(../../img/newVioletta\ \(1\).webp);
    background-size: 100%;
    background-repeat: no-repeat;
}

.boxIncNumber .total__add {
    width: 50px;
    line-height: 24px;
    height: 17px;
    padding: 3px 0 0 0;
    display: block;
}

.promoContentBlock {
    width: 164px;
    background: rgba(0, 0, 0, 0.3);
    height: 230px;
}

.text__boxIncNumber {
    color: var(--Text-2, #9AA0B0);
    text-align: center;
    line-height: 20px;
}

.boxIncNumber .box__slots {
    margin-bottom: 0;
}

.ticketTop {
    line-height: 28px;

}

.qTicketBottom {
    bottom: 30px;
}

@media (max-width: 1150px) {


    .modal__content {
        overflow: auto;
    }

    .boxMarket .iconClassTicket,
    .sliderModalMarket .icon__card__Violetta,
    .sliderModalMarket .icon__card__Sunshine,
    .sliderModalMarket .icon__card__Legend,
    .sliderModalMarket .icon__card__Epic,
    .sliderModalMarket .icon__card__Rare,
    .sliderModalMarket .icon__card__Frostee,
    .sliderModalMarket .icon__card__Uncommon,
    .sliderModalMarket .icon__card__Leaflynn,
    .sliderModalMarket .icon__card__Common,
    .sliderModalMarket .icon__card__Steelade,
    .sliderModalMarket .icon__card__Promo,
    .sliderModalMarket .icon__card__Jing,
    .sliderModalMarket .icon__card__Magmus,
    .sliderModalMarket .icon__card__spinny {
        top: 0px;
        left: 0px;

    }

    #spinn__sliderMarket {
        width: 100px;
        height: 160px;
    }

    .sliderModalMarket {
        display: flex;
    }

    #modal1 .modal.boxFirstStor {
        width: 300px;
        height: 200px;
    }

    #modal1 .modal.boxMarket {
        width: 300px;
        height: 220px;
    }

    #modal1 .swiper-wrapper {
        margin-top: 6px;
    }

    .sliderModalMarket {
        width: 200px;
        margin-bottom: 0;
    }

    #spinn__sliderMarket .swiper-slide {
        width: 100px;
    }

    #modal1 .modal,
    #modal13 .modal {
        width: 300px;
        height: 500px;
    }

    .icon__card__Violetta,
    .icon__card__Sunshine,
    .icon__card__Legend,
    .icon__card__Epic,
    .icon__card__Rare,
    .icon__card__Frostee,
    .icon__card__Uncommon,
    .icon__card__Leaflynn,
    .icon__card__Common,
    .icon__card__Steelade,
    .icon__card__Promo,
    .icon__card__Jing,
    .icon__card__Magmus,
    .icon__card__spinny {
        position: fixed;
        top: 20px;
        left: 70px;


    }

    .iconClassTicket {
        position: fixed;
        top: 8px;
        left: 70px;
    }

    #spinn__sliderMarket {
        padding: 8px;
    }

    #modal1 .container__modal,
    #modal13 .container__modal {
        flex-direction: column;
    }

    .cintainer__purchase {
        margin-top: 127px;
        margin-left: 0;
    }

    #modal1 {
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 20px;
    }

    #modal1 .modal.boxFirstStor {
        margin-top: 260px;
        width: 300px;
        height: 160px;
    }
}

@media (max-width: 610px) {
    #modal10 .u {
        width: 300px;
    }
}